import { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from 'bv-components';
import { t } from 'bv-i18n';

const SOWCollectionBoolean = ({
  placeholder, value, formInvalid, forceValidation, onChange,
}) => {
  const [active, setActive] = useState(value);
  const clickButton = (areAdditionalIncomes) => {
    setActive(areAdditionalIncomes);
    onChange({ target: { value: areAdditionalIncomes } });
  };

  return (
    <div className="sow-collection-add-sources">
      <p>{t(placeholder)}</p>
      <div>
        <Button
          noClass
          type="button"
          className={classnames('bvs-button-chip is-small', { disabled: formInvalid, active })}
          label={t('sow_collection.additional_income_source.true')}
          onClick={() => clickButton(true)}
        />
        <Button
          noClass
          type="button"
          className={classnames('bvs-button-chip is-small', { disabled: formInvalid, active: active === false })}
          label={t('sow_collection.additional_income_source.false')}
          onClick={() => clickButton(false)}
        />
      </div>
      {forceValidation && active === null && (
        <span className="form-control-error-message">
          {t('sow_collection.validation.mandatory_yes_no')}
        </span>
      )}
    </div>
  );
};

SOWCollectionBoolean.propTypes = {
  value: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
  formInvalid: PropTypes.bool.isRequired,
  forceValidation: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SOWCollectionBoolean;
