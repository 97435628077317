import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { v as bvVar } from 'bv';
import { isEmpty } from 'underscore';

import {
  required, minLength, maxLength, amount, composeValidators,
} from 'SOWCollection/validators';

import SOWCollectionFreetext from './sow_collection_freetext';
import SOWCollectionAmount from './sow_collection_amount';
import SOWCollectionBoolean from './sow_collection_boolean';
import SOWCollectionSelect from './sow_collection_select';

const tKeyPrefix = 'sow_collection.validation';

const fieldsFactory = (type, { minLength: min, maxLength: max }) => {
  const fieldsConfiguration = {
    freetext: {
      component: SOWCollectionFreetext,
      validators: [
        required(t(`${tKeyPrefix}.mandatory_field`)),
        minLength(min, t(`${tKeyPrefix}.min_length`, { minLength: min })),
        maxLength(max, t(`${tKeyPrefix}.max_length`, { maxLength: max })),
      ],
    },
    amount: {
      component: SOWCollectionAmount,
      validators: [
        required(t(`${tKeyPrefix}.mandatory_field`)),
        amount(t(`${tKeyPrefix}.no_alphabetic`)),
        minLength(min, t(`${tKeyPrefix}.min_length`, { minLength: min })),
        maxLength(max, t(`${tKeyPrefix}.max_length`, { maxLength: max })),
      ],
      className: 'sow-collection-pair-field-first',
    },
    period: {
      component: SOWCollectionSelect,
      className: 'sow-collection-pair-field-last',
      validators: [
        required(t(`${tKeyPrefix}.mandatory_field`)),
      ],
    },
    boolean: {
      component: SOWCollectionBoolean,
    },
  };
  return fieldsConfiguration[type] || {};
};

const SOWCollectionFieldFactory = ({
  name, type, placeholder, formInvalid, forceValidation, value, onChange,
}) => {
  const validationConfiguration = useSelector((state) => state.sowCollection.validation);
  const field = fieldsFactory(type, validationConfiguration[bvVar('brandId')] || validationConfiguration[1]);
  if (isEmpty(field)) return null;

  const { component: Component, validators = [], className = '' } = field;

  return (
    <Component
      name={name}
      placeholder={placeholder}
      validators={composeValidators(validators)}
      className={className}
      formInvalid={formInvalid}
      forceValidation={forceValidation}
      value={value}
      onChange={onChange}
    />
  );
};

SOWCollectionFieldFactory.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  formInvalid: PropTypes.bool.isRequired,
  forceValidation: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
};

SOWCollectionFieldFactory.defaultProps = {
  forceValidation: false,
};

export default SOWCollectionFieldFactory;
